@import './shared/styles/_vuetify-overrides.scss';




































































































































































































































































































































































































.separator td {
	background-color: #f5f5f5;
	height: 5px !important;
}
